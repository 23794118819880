<template>
    <div>
      <div class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
<!--              <img src="https://qiniu.youjiamall.cn/yjpc/banner5.png" alt="">-->

              <img src="@/assets/img/test.png" alt="">
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>

        <!--    活动类型    -->
        <div class="act_type">
          <subTitle en-tit="ACTIVITY TYPE" zh-tit="活动类型"></subTitle>
          <div class="act_imgs_box" v-if="navCurrent == 0">
            <p class="imgs_one"><img src="https://qiniu.youjiamall.cn/yjpc/sg5.png" alt=""></p>
            <div class="imgs_two_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sg1.png" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sg2.jpeg" alt=""></p>
            </div>
            <p class="imgs_three"><img src="https://qiniu.youjiamall.cn/yjpc/sg4.jpeg" alt=""></p>
            <div class="imgs_four_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sg3.png" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sg6.png" alt=""></p>
            </div>
          </div>
          <div class="act_imgs_box" v-if="navCurrent == 1">
            <p class="imgs_one"><img src="https://qiniu.youjiamall.cn/yjpc/zt5.png" alt=""></p>
            <div class="imgs_two_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/zt1.jpeg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/zt2.png" alt=""></p>
            </div>
            <p class="imgs_three"><img src="https://qiniu.youjiamall.cn/yjpc/zt3.jpeg" style="object-fit: unset ;" alt=""></p>
            <div class="imgs_four_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/zt4.jpeg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/zt6.png" alt=""></p>
            </div>
          </div>
          <div class="act_imgs_box" v-if="navCurrent == 2">
            <p class="imgs_one"><img src="https://qiniu.youjiamall.cn/yjpc/sn7.png" alt=""></p>
            <div class="imgs_two_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sn1.jpeg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sn3.jpeg" alt=""></p>
            </div>
            <p class="imgs_three"><img src="https://qiniu.youjiamall.cn/yjpc/sn2.png"  alt=""></p>
            <div class="imgs_four_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sn4.png" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sn5.jpeg" alt=""></p>
            </div>
          </div>
          <div class="act_imgs_box" v-if="navCurrent == 3">
            <p class="imgs_one"><img src="https://qiniu.youjiamall.cn/yjpc/yd5.jpg" alt=""></p>
            <div class="imgs_two_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/yd1.jpg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/yd2.jpg" alt=""></p>
            </div>
            <p class="imgs_three"><img src="https://qiniu.youjiamall.cn/yjpc/yd3.JPG"  alt=""></p>
            <div class="imgs_four_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/yd4.jpg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/yd6.jpg" alt=""></p>
            </div>
          </div>

          <div class="act_imgs_box" v-if="navCurrent == 4">
            <p class="imgs_one"><img src="https://qiniu.youjiamall.cn/yjpc/sr2.jpg" alt=""></p>
            <div class="imgs_two_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sr1.jpg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sr3.jpg" alt=""></p>
            </div>
            <p class="imgs_three"><img src="https://qiniu.youjiamall.cn/yjpc/sr4.jpg"  alt=""></p>
            <div class="imgs_four_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sr5.jpg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/sr7.jpg" alt=""></p>
            </div>
          </div>

          <div class="act_imgs_box" v-if="navCurrent == 5">
            <p class="imgs_one"><img src="https://qiniu.youjiamall.cn/yjpc/nh1.jpg" alt=""></p>
            <div class="imgs_two_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/nh2.jpg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/nh4.jpg" alt=""></p>
            </div>
            <p class="imgs_three"><img src="https://qiniu.youjiamall.cn/yjpc/nh3.jpg"  alt=""></p>
            <div class="imgs_four_box">
              <p><img src="https://qiniu.youjiamall.cn/yjpc/nh5.jpg" alt=""></p>
              <p><img src="https://qiniu.youjiamall.cn/yjpc/nh6.jpg" alt=""></p>
            </div>
          </div>

          <ul class="act_nav">
            <li v-for="(item,index) in navArr"
                :key="index"
                :class="{activeColor:navCurrent === index}"
                @mousemove="mouseChange(index)">
              <img class="down_img" v-if="navCurrent == index" src="@/assets/img/up_img.png" alt="">
              <div v-else class="down_img"></div>
              <p><img :src="item.icon" alt=""></p>
<!--              <p v-else><img :src="item.icon" alt=""></p>-->
              <span>{{item.name}}</span>
            </li>
          </ul>
        </div>
        <div class="hd_wrap">
            <pubBoxTit title="活动定制" ltit="CUSTOMIZED" paddingTop="50"></pubBoxTit>
            <ul class="hd_mai">
                <li v-for="(item,index) in hdArr" :key="index" @mousemove="hdClick(index)" :class="{mai_active:current == index}">
                  <p class="mai_tips">{{ item.tips}}</p>
                  <p class="mai_ltit">{{item.ltit}}</p>
                  <p class="mai_text">
                    <span class="text_top">{{item.ttext}}</span><br>
                    {{item.btext}}
                  </p>
                  <img v-if="current == index" :src="item.selIcon" alt="">
                  <img v-else :src="item.icon" alt="">
                </li>
            </ul>
        </div>
      <pubFormInput></pubFormInput>
    </div>
</template>

<script>
import pubBoxTit from '@/components/pubBoxTit.vue'
import subTitle from '@/components/subTitle.vue'
import pubFormInput from "@/components/pubFormInput";

export default {
    components: {
        pubBoxTit,
        subTitle,
        pubFormInput
    },
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
    data() {
        return {
          current:0,
          navCurrent:0,
          navArr:[{
            name:'手工DIY',
            icon:'https://qiniu.youjiamall.cn/yjpc/hd_icon1.png',
            icon_sel:'https://qiniu.youjiamall.cn/yjpc/icon_1.png'
          },{
            name:'节日主题活动',
            icon:'https://qiniu.youjiamall.cn/yjpc/hd_icon2.png',
            icon_sel:'https://qiniu.youjiamall.cn/yjpc/icon_2.png'
          },{
            name:'室内团建',
            icon:'https://qiniu.youjiamall.cn/yjpc/hd_icon3.png',
            icon_sel:'https://qiniu.youjiamall.cn/yjpc/icon_3.png'
          },{
            name:'趣味运动会',
            icon:'https://qiniu.youjiamall.cn/yjpc/hd_icon4.png',
            icon_sel:'https://qiniu.youjiamall.cn/yjpc/icon_4.png'
          },{
            name:'生日会',
            icon:'https://qiniu.youjiamall.cn/yjpc/hd_icon5.png',
            icon_sel:'https://qiniu.youjiamall.cn/yjpc/icon_5.png'
          },{
            name:'年会策划',
            icon:'https://qiniu.youjiamall.cn/yjpc/hd_icon6.png',
            icon_sel:'https://qiniu.youjiamall.cn/yjpc/icon_6.png'
          }],
          hdArr:[{
            tips:'01',
            ltit:'沟通',
            ttext:'活动内容',
            btext:'了解公司企业文化，所属行业确定公司需求的活动诉求',
            selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_7.png',
            icon:'https://qiniu.youjiamall.cn/yjpc/icon_7_1.png'
          },{
            tips:'02',
            ltit:'定制',
            ttext:'活动方案',
            btext:'根据公司诉求，定制专属活动方案，节约公司成本',
            selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_8_1.png',
            icon:'https://qiniu.youjiamall.cn/yjpc/icon_8.png'
          },{
            tips:'03',
            ltit:'执行',
            ttext:'执行活动方案',
            btext:'根据活动方案，进行实施，确保公司福利发放到位',
            selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_9_1.png',
            icon:'https://qiniu.youjiamall.cn/yjpc/icon_9.png'
          },{
            tips:'04',
            ltit:'售后',
            ttext:'活动方案回访',
            btext:'根据活动完成情况进行回访确保业务后续合作',
            selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_10_1.png',
            icon:'https://qiniu.youjiamall.cn/yjpc/icon_10.png'
          }]
        };
    },

    methods: {
      hdClick(index) {
        this.current = index;
      },
      mouseChange(index) {
        this.navCurrent = index;
      }
    }
}

</script>
<style scoped>
/*.swiper {*/
/*}*/
/*.swiper img {*/
/*  object-fit: unset;*/
/*}*/
.hd_mai  {
  display: flex;
  margin: 99px 0 110px;
  padding: 0 120px;
  box-sizing: border-box;
}
.hd_mai li {
  padding: 31px 38px 22px;
  box-sizing: border-box;
  width: 284px;
  height: 366px;
  background: #fff;
  border-radius: 10px;
  margin-right: 20px;
}
.hd_mai li img {
  margin-top: 48px;
}
.mai_text {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
}
.mai_tips {
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #666666;
}
.mai_ltit {
  color: #666666;
  margin: 14px 0 34px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;

}
.text_top {
  display: inline-block;
  margin-bottom: 5px;
}
.hd_mai .mai_active {
  background: url("https://qiniu.youjiamall.cn/yjpc/hd_bg1.png")no-repeat;
  background-size: 100% 100%;
}
.hd_mai .mai_active:nth-child(2) {
  background: url("https://qiniu.youjiamall.cn/yjpc/hd_bg2.png")no-repeat;
  background-size: 100% 100%;
}
.hd_mai .mai_active:nth-child(3) {
  background: url("https://qiniu.youjiamall.cn/yjpc/hd_bg3.png")no-repeat;
  background-size: 100% 100%;
}
.hd_mai .mai_active:nth-child(4) {
  background: url("https://qiniu.youjiamall.cn/yjpc/hd_bg4.png")no-repeat;
  background-size: 100% 100%;
}
.mai_active .mai_text {
  color: #fff;
}
.mai_active .mai_tips {
  color: #fff;
}
.mai_active .mai_ltit {
  color: #fff;
}

.act_type {
  padding: 50px 120px 40px;
  box-sizing: border-box;
}
.act_imgs_box {
  display: flex;
  height: 340px;
  margin-top: 38px;
  margin-bottom: 24px;
}
.act_imgs_box p {
  background: #fff;
  border-radius: 8px;
}
.imgs_one {
  width: 241px;
  height: 340px;
  margin-right: 10px;
}
.imgs_two_box p{
  width: 267px;
  height: 164px;
}
.imgs_three {
  margin: 0 10px;
  width: 397px;
  height: 340px;
}
.imgs_four_box p {
  width: 267px;
  height: 164px;
}
.imgs_two_box p:nth-child(1),
.imgs_four_box p:nth-child(1){
  margin-bottom: 11px;
}
.act_imgs_box img{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover ;
}
.act_nav {
  display: flex;
  justify-content: space-between;
  padding: 0 83px 0 71px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
}
.down_img {
  width: 75px;
  height: 30px;
}
.act_nav p {
  border: 2px solid #EEEEEE;
  width: 101px;
  height: 101px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #007858;
  box-sizing: border-box;
  margin-bottom: 14px;
}
.act_nav p img {
  margin: auto;
  /*transform: scale(1.1);*/
}
.activeColor p {
  border: 2px solid #007858;
}
.activeColor {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #007858;

}
.hd_wrap {

  background: #F4F7FA;
  padding-bottom: 1px;
  box-sizing: border-box;
}

@media (max-width: 1440px) {
  .act_nav p {

    margin-bottom: 35px;
  }
}
</style>
